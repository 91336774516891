import React from 'react';
import { Link } from 'react-router-dom'

import { ButtonGroup, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function BtnVisao(props) {
    const disable = (props.status === "Reprovado" || props.status === "Aprovado");

    return (
        <ButtonGroup>
            <Link to={`/detalheMovimento/${props.idmov}/${props.idaprovacao}/${disable === true ? 1 : 0}`}
                className="btn btn-primary btn-sm btn-grid mr-1" data-placement="right" title="Detalhes do Movimento">
                <FontAwesomeIcon icon="list-alt" />
            </Link> {" "}

            {disable === false ?
                <>
                    <Link to={`/reavaliarMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido.toFixed(2)}/${props.idaprovacao}/0`}
                        className="btn btn-warning btn-sm btn-grid mr-1" data-placement="right" title="Reavaliar Movimento" >
                        <FontAwesomeIcon icon="undo-alt" />
                    </Link> {" "}

                    <Link to={`/reprovaMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido.toFixed(2)}/${props.usuariomestre}/${props.dependencia}/${props.idaprovacao}/${props.codusuario}/0`}
                        className="btn btn-danger btn-sm btn-grid mr-1" data-placement="right" title="Reprovar Movimento" >
                        <FontAwesomeIcon icon="times-circle" />
                    </Link> {" "}

                    <Link to={`/aprovaMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido.toFixed(2)}/${props.codtmv}/${props.usuariomestre}/${props.dependencia}/${props.idaprovacao}/${props.sequencial}/${props.codusuario}/${props.nomeVendedor}/0`}
                        className="btn btn-success btn-sm mr-1 btn-grid" data-placement="right" title="Aprovar Movimento" >
                        <FontAwesomeIcon icon="check" />
                    </Link> {" "}
                </>
                :
                <>
                    <Button variant="warning" size="sm" disabled className="mr-1">
                        <FontAwesomeIcon icon="undo-alt" />
                    </Button>

                    <Button variant="danger" size="sm" disabled className="mr-1">
                        <FontAwesomeIcon icon="times-circle" />
                    </Button>

                    <Button variant="success" size="sm" disabled className="mr-1">
                        <FontAwesomeIcon icon="check" />
                    </Button>
                </>
            }
        </ButtonGroup>
    );
}