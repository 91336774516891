import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useHistory, Redirect } from 'react-router-dom';
import useKeyPress from '../../util/useKeyPress';

import { Modal, Button, Alert } from 'react-bootstrap';
import Icone from '../Icone';
import DetalheLancamento from '../../pages/detalheLancamento';


export default function ModalAprovMultiplaNf(props) {
    const [show, setShow] = useState(false);
    const [disabledAprov, setDisableAprov] = useState(false);
    const [mensagem, setMensagem] = useState()
    const [erro, setErro] = useState('');
    const [dados, setDados] = useState();
    const history = useHistory();

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setErro('');
        setShow(false);
        setDisableAprov(false);
    };

    let notasFiscais = JSON.parse(sessionStorage.getItem("idsMovimentos"));

    //Se pressionado a tecla A, abre o modal de aprovação
    const keyShow1 = useKeyPress('a');
    const keyShow2 = useKeyPress('A');
    const keyShow3 = useKeyPress('Alt');
    useEffect(() => {
        if (((keyShow1 || keyShow2) && keyShow3) && !show && !props.visao) {
            handleShow();
        }
        // eslint-disable-next-line
    }, [keyShow1, keyShow2])




    //Se pressionado a tecla ENTER com o modal aberto
    //faz a aprovação do lançamento
    const keyAprova = useKeyPress('Enter')
    useEffect(() => {
        if (keyAprova && show && !erro) {
            AprovaNfMultipla();
        }
        // eslint-disable-next-line
    }, [keyAprova])

    // console.log("NODE =====>", props.notasFiscais);

    useEffect(() => {
        setMensagem("Deseja realmente aprovar o(s) lançamento(s) selecionado(s) ? ")

        //se for lançamento com outros lançamentos vinculados
        if (show && !props.visao && props.notasFiscais) {

            let selec = props.notasFiscais.selectionContext.selected;
            if (selec.indexOf(props.idlanAtual) < 0 && !props.pagInicial) {
                setErro(`É obrigatória a seleção do lançamento atual. (Ref: ${props.idlanAtual} )`);
                setDisableAprov(true);
            }
            else {
                //zera as mensages e desbloqueia o botão quando sai do modal
                if (erro) {
                    setErro("");
                    setDisableAprov(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [show])

    async function AprovaNfMultipla() {
        setDisableAprov(true);
        setMensagem('Aprovando, por gentileza aguarde...')

        let selec = props.notasFiscais.selectionContext.selected;
        let tam = selec.length;


        selec = selec.map(str => JSON.parse(str));
        setShow(true);
        for (var i = 0; i < tam; i++) {

            let config = {
                headers: {
                    'IDMOV': selec[i].botoes.props.idmov,
                    'CODTMV': selec[i].botoes.props.codtmv,
                    'CODUSUARIOAPROVACAO': sessionStorage.getItem('RC_USR'),
                    'CODUSUARIOMESTRE': selec[i].botoes.props.usuariomestre,
                    'DEPENDENCIA': selec[i].botoes.props.dependencia,
                    'IDAPROVACAO': selec[i].botoes.props.idaprovacao,
                    'SEQUENCIAL': selec[i].botoes.props.sequencial,
                    'CODUSUARIOORIGEM': selec[i].botoes.props.codusuario,
                    'NOME_VENDEDOR': selec[i].botoes.props.nomeVendedor,
                }
            }
            await api.get('/AprovacaoMovimentoObras', config)
                .then((response) => {
                    console.log("Aprovados");
                })
                .catch((err) => {
                    setErro(err.toString());
                })
        }
        if (props.visao == false) {
            history.push("/financeiro");
        } else {
            setShow(false);
            window.location.reload();
        }
    }


    return (
        <>
            <Button variant="success" onClick={handleShow} disabled={props.disabled}>
                <Icone icon="thumbs-up" />
                {props.visao === false ? ' Aprovar' : ''}
                {props.visaoGeral === true ? " Aprovar" : ""}
            </Button>


            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>Aprovação de Lançamentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {erro ?
                        <Alert variant="danger">
                            <Icone icon="times" />
                            &nbsp; {erro}
                        </Alert>
                        :
                        mensagem
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancelar
                    </Button>

                    <Button variant="success" onClick={() => AprovaNfMultipla()} disabled={disabledAprov}>
                        <Icone icon="thumbs-up" className="mr-2" />
                        &nbsp; Aprovar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}