import React from 'react';
import { Container, Card, Row, ButtonGroup } from 'react-bootstrap'
import CardTitulo from '../cardTitulo';
import BtnImpressao from '../btnImpressao';
import ModalMedicao from '../../pages/detalheMovimento/components/modalMedicao';
import ImpressQuadroComparativo from '../../components/impressQuadroComparativo';


export default function CardDetalhes(props) {

    console.log(props);

    let idmov;
    let numeromov;
    let codcotacao;

    if (props.idmov) {
        idmov = props.idmov;
    } else {
        idmov = 0;
    }

    if (props.dados) {
        codcotacao = props.dados.codcotacao;
        numeromov = props.dados.numeromov;
    } else {
        codcotacao = 0;
        numeromov = 0;
    }

    console.log(props)

    const impressao = (idmov, numeromov, codcotacao) => (
        <ButtonGroup>
            {codcotacao !== "" ? <ImpressQuadroComparativo codcotacao={codcotacao} /> : ''}
            <ModalMedicao idmov={idmov} numeromov={numeromov} />
        </ButtonGroup>
    )

    return (
        <Container className="container-principal">
            <Card className="card-detalhe">
                <Card.Body>
                    <Row className="mt-3 mb-3 align-items-center">
                        <CardTitulo
                            titulo={props.titulo}
                            subtitulo={props.subtitulo}
                        />
                        {props.exibe == 1 &&
                            <>
                                <div
                                    style={{
                                        fontSize: '25px',
                                        marginRight: '150px',
                                        color: '#FF0000'
                                    }}
                                >
                                    {!props.lancamento &&
                                        <div style={{ fontSize: '17px' }}>
                                            Valor total: <b>{props.valorTotal}</b>
                                        </div>
                                    }

                                    Valor: <b>{props.valor}</b>

                                    {!props.lancamento &&
                                        <div style={{ fontSize: '17px' }} >
                                            Saldo: <b>{props.valorSaldo}</b>
                                        </div>
                                    }
                                </div>


                            </>
                        }

                        {props.exibeBotao == true &&
                            impressao(idmov, numeromov, codcotacao)}
                        {/* <BtnImpressao idmov={props.idmov} visible={props.btnImprimeVisible} disabled={props.disabled} /> */}

                        {
                            //Elaborar um componente para a impressão da nota fiscal (quando houver nota fiscal)
                            props.notaFiscal == true &&
                            <BtnImpressao idmov={props.idmov} visible={props.btnImprimeVisible} disabled={true} />
                            // <BtnImpressao idmov={props.idmov} visible={props.btnImprimeVisible} disabled={props.disabled} />
                        }
                    </Row>
                    {props.children}
                </Card.Body>
            </Card>
        </Container>
    );
}