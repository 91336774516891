import moment from 'moment';

// Converte pra data no formato DD/MM/YYYY
export const formataData = (value) => {
    // console.log(value)
    return moment.utc(value).format('DD/MM/YYYY');
}


// Converte pra data no formato DD/MM/YYYY HH:mm:ss
export const formataDataHora = (value) => {
    return (value) ? moment.utc(value).format('DD/MM/YYYY HH:mm:ss') : '';
}

// Converte pra data no formato DD/MM/YYYY HH:mm
export const formataDataHora2 = (value) => {
    return (value) ? moment.utc(value).format('DD/MM/YYYY HH:mm') : '';
}


// Converte um valor para o formato monetario brasileiro R$ 9.999,99
export const formataDinheiro = (value) => {
    if (value == null){
        value = 0;
    }
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
}

