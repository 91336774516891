import React, { useState } from 'react';
import api from '../../services/api';
import { Button, Modal, Badge, ButtonGroup } from 'react-bootstrap';

import ModalMedicao from '../../pages/detalheMovimento/components/modalMedicao';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

export default function ImpressQuadroComparativo(props) {
    const [show, setShow] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [dados, setDados] = useState('');
    const [erro, setErro] = useState({ mensagem: '', tipo: '', semRelatorio: true });

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    function getRelatorio() {
        setCarregando(true);

        let config = {
            headers: {
                'CODCOTACAO': props.codcotacao,
            }
        }

        api.get('/QuadroComparativo', config)
            .then((response) => {
                const res = response.data;

                switch (res.status) {
                    case "0":
                        setErro({ mensagem: "Sem dados de relatório", semRelatorio: true, tipoMensagem: "primary" })
                        break;

                    case "1":
                        setDados(res.arquivo)
                        handleShow();
                        break;

                    default:
                        setErro({ mensagem: "Erro ao gerar relatório!", semRelatorio: true, tipoMensagem: "danger" })
                }
            })
            .catch((err) => setErro({ mensagem: "Erro ao gerar relatório!", semRelatorio: true, tipoMensagem: "danger" }))
            .finally(() => setCarregando(false));
    }


    function GeraUrlPDF() {
        let base64str = dados;

        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);

        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        var blob = new Blob([view], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(blob);


        return fileURL;
    }



    function DownloadPDF() {
        var a = document.createElement('a');
        a.href = GeraUrlPDF();
        a.target = "_blank";
        a.download = `Quadro-comparativo-${props.codcotacao}.pdf`;
        document.body.appendChild(a);
        a.click();
    }

    return (
        <>
            <Badge variant={erro.tipoMensagem} className="mr-2">
                {erro.mensagem}
            </Badge>{' '}

            <Button variant="light" onClick={() => getRelatorio()}>
                {carregando ?
                    <>
                        <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                        carregando...
                    </>
                    :
                    <>
                        <FontAwesomeIcon icon="print" className="mr-2" />
                        Quadro Comparativo
                    </>
                }
            </Button>

            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title>Visualizar Quadro Comparativo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <object data={GeraUrlPDF()} type="application/pdf" style={{ width: '100%', height: '75vh' }}>
                        <div className="m-3" >Visualização do PDF não suportada neste dispositivo, utilize o botão de download abaixo.</div>
                    </object>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => DownloadPDF()}>
                        <FontAwesomeIcon icon="file-download" className="mr-2" />
                        Fazer Download em PDF
                    </Button>

                    <Button variant="primary" onClick={() => handleClose()}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}