import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { formataData, formataDataHora, formataDinheiro } from '../../../../util/formatacao';

export default function FormDetalheLanc(props) {
    return (
        <Form>
            {console.log(props)}
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="IdMov">Id Lançamento</Form.Label>
                    <Form.Control readOnly id="IdMov" value={props.idlan} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="numDoc">Num. Documento</Form.Label>
                    <Form.Control readOnly id="numDoc" value={props.numerodocumento} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="fornecedor">Fornecedor</Form.Label>
                    <Form.Control readOnly id="fornecedor" value={props.codcfo + ' - ' + props.fornecedor} />
                </Form.Group>

            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md={6}>
                    <Form.Label htmlFor="inputTipoMovimento">Tipo de Documento</Form.Label>
                    <Form.Control readOnly id="inputTipoMovimento" value={props.tipoDocto} />
                </Form.Group>


                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputCondPag">Plano Financeiro</Form.Label>
                    <Form.Control readOnly id="inputCondPag" value={props.planoFinacneiro} />
                </Form.Group>
            </Form.Row>
            <Form.Row>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrBruto">Data de Emissão</Form.Label>
                    <Form.Control readOnly id="inputQuantItens"
                        value={formataData(props.dataemissao)} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataVenc">Data Vencimento</Form.Label>
                    <Form.Control readOnly id="inputDataVenc" value={formataData(props.datavencimento)} />

                </Form.Group>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataPrevBaixa">Data de Prev. de Baixa</Form.Label>
                    <Form.Control readOnly id="inputDataPrevBaixa" value={formataData(props.dataprevbaixa)} />
                </Form.Group>


                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataAprovacao">Data da Aprovação</Form.Label>
                    <Form.Control readOnly id="VlrLiquido" value={props.dataaprovador ? formataData(props.dataaprovador) : "Sem Dados"} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="Aprovador">Aprovador Financeiro</Form.Label>
                    <Form.Control readOnly id="Aprovador" value={(props.nomeaprovador ?? '') + ' - ' + formataDataHora(props.dataaprovador)} />
                </Form.Group>
            </Form.Row>

            {props.idmov === 0 ?
                <Form.Row>
                    <Form.Group as={Col} md={3}>
                        <Form.Label htmlFor="aprovadorcompra">Aprovador de Compra</Form.Label>
                        <Form.Control readOnly id="aprovadorcompra" value={props.aprovadorcompra} />
                    </Form.Group>
                </Form.Row>
                :
                <></>
            }

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="historico">Histórico</Form.Label>
                    <Form.Control as="textarea" rows="5" readOnly id="historico" value={props.historico} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
}