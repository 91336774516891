import React from 'react';
import { Dropdown, SplitButton, Form } from 'react-bootstrap'
import ModalSobre from '../../../modalSobre';
import ModalAlteraSenha from '../../../modalAlteraSenha';

import { Link } from 'react-router-dom';
import { logout } from '../../../../services/auth';
import packageJson from '../../../../../package.json';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalAssinatura from '../../../modalAssinatura';
library.add(fas);


export default function DropDownUsuario() {

    return (
        <>
            <Form inline>
                <SplitButton alignRight variant="primary" title={
                    <div><FontAwesomeIcon icon="user-circle" className="mr-2" /> Olá, {sessionStorage.getItem('RC_NOMEUSR')}</div>}>
                    <Dropdown.Item style={{ fontSize: '12px' }} disabled>
                        <p className="mb-0">RC Aprovação Web</p>
                        <p className="mb-0">Versão: {packageJson.version}</p>
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Link to="/SelecionaEmpresa/1" className="dropdown-item">
                        <FontAwesomeIcon icon="building" className="mr-2" />
                        Selecionar Empresa
                    </Link>

                    <Dropdown.Divider />

                    <Dropdown.Item eventKey="1" disabled>
                        <FontAwesomeIcon icon="cog" className="mr-2" />
                        Configurações
                    </Dropdown.Item>

                    <ModalAssinatura />

                    <ModalAlteraSenha showButton={true} />

                    <Dropdown.Item href="https://rcsolucoes.movidesk.com/kb/pt-br/" target="_blank">
                        <FontAwesomeIcon icon="book" className="mr-2" />
                        Base de Conhecimento
                    </Dropdown.Item>

                    <Dropdown.Item href="https://rcsolucoes.movidesk.com/kb/pt-br/form/813/contato" target="_blank">
                        <FontAwesomeIcon icon="bug" className="mr-2" />
                        Reportar Erro
                    </Dropdown.Item>

                    <ModalSobre />

                    <Dropdown.Divider />

                    <Dropdown.Item className="text-center">
                        <Link to="/app" className="btn btn-danger" onClick={() => logout()}>
                            <FontAwesomeIcon icon="power-off" className="mr-2" />
                            Encerrar Sessão
                        </Link>
                    </Dropdown.Item>

                </SplitButton>
            </Form >
        </>
    );
}