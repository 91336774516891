import SignaturePad from 'react-signature-canvas'
import { Button } from 'react-bootstrap';

import React, { useState, useRef, useEffect } from "react";

import api from '../../../../services/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';



import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ExibirAssinatura from '../exibirAssinatura';
library.add(fas);
export default function CadastrarAssinatura({ show, setShow }) {
    const [refreshExibirAssinatura, setRefreshExibirAssinatura] = useState(false); 
    const UploadComponentRef = useRef();

    const salvaAssinatura = async (base64ImageData) => {
        try {
            let params = {
                codusuario: sessionStorage.getItem("RC_NOMEUSR"),
                assinatura: base64ImageData.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            };
    
            const response = await fetch(`${window.url_API}RegistraAssinatura?HASH=${sessionStorage.getItem('RC_HASH') + Math.random()}&ALIAS=${window.alias_RC}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("RC_TOKEN")}`
                },
                body: JSON.stringify(params)
            });
    
            if (!response.ok) {
                throw new Error('Erro na requisição');
            }
    
            const data = await response.json();
            console.log('Resposta da requisição:', data);
        } catch (error) {
            console.error('Erro ao enviar requisição:', error);
        }
    };
    

    var ObjUploadTraduzido = {
        "Browse": "Escolher Imagem",
        "Clear": "Limpar",
        "Upload": "Enviar",
        "dropFilesHint": "Ou arraste arquivos aqui.",
        "invalidMaxFileSize": "Tamanho do arquivo muito grande",
        "invalidMinFileSize": "Tamanho do arquivo muito pequeno",
        "invalidFileType": "Tipo de arquivo inválido",
        "uploadFailedMessage": "Falha ao enviar",
        "uploadSuccessMessage": "Envio concluído com sucesso!",
        "removedSuccessMessage": "Arquivo removido com sucesso!",
        "removedFailedMessage": "Não foi possível remover arquivo.",
        "inProgress": "Enviando...",
        "readyToUploadMessage": "Pronto para envio.",
        "abort": "Abortar",
        "remove": "Remover",
        "cancel": "Cancelar",
        "delete": "Deletar arquivo",
        "pauseUpload": "Envio pausado",
        "pause": "Pausar",
        "resume": "Continuar",
        "retry": "Tentar novamente",
        "fileUploadCancel": "Envio cancelado",
        "invalidFileSelection": "Arquivos selecionados são inválidos",
        "totalFiles": "Arquivos totais",
        "size": "Tamanho"
    }

    useEffect(() => {
        (async () => {
            try {
                translateUploadComponent();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [show])

    useEffect(() => {
        (async () => {
            try {
                translateUploadComponent();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])

    useEffect(() => translateUploadComponent(), [UploadComponentRef]);


    const translateUploadComponent = () => {
        try {
            UploadComponentRef.current.l10n.currentLocale = ObjUploadTraduzido;
            UploadComponentRef.current.l10n.localeStrings = ObjUploadTraduzido;
            UploadComponentRef.current.preLocaleObj = ObjUploadTraduzido;
            UploadComponentRef.current.localeText = ObjUploadTraduzido;
        } catch (error) {
            console.log(error);
        }
    }

    const sigCanvas = useRef({});

    const clear = () => sigCanvas.current.clear();

    const save = async () => {
        const newImageURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        await salvaAssinatura(newImageURL)
        setRefreshExibirAssinatura(prevState => !prevState);
    }

    let uploadObj;
    const asyncSettings = {
        saveUrl: `${window.url_API}RegistraAssinatura?HASH=${sessionStorage.getItem('RC_HASH')  + Math.random()}&ALIAS=${window.alias_RC}`,
        removeUrl: `${window.url_API}RegistraAssinatura?HASH=${sessionStorage.getItem('RC_HASH')  + Math.random()}&ALIAS=${window.alias_RC}`
    }
    console.log(window);
    let dropAreaRef;

    async function convertBinaryToBase64(binaryData) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function () {
                var base64Data = reader.result;
                resolve(base64Data);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsDataURL(new Blob([binaryData]));
        });
    }
    


    const imageUploading = async (e) => {
        try {
            let params;
            e.currentRequest.setRequestHeader("Authorization", `Bearer ${sessionStorage.getItem("RC_TOKEN")}`);
            console.log(e);
            // Exemplo de uso:
            var binaryImageData = e.fileData.rawFile;
            const base64ImageData = await convertBinaryToBase64(binaryImageData);
    
            console.log(base64ImageData);
            params = {
                codusuario: sessionStorage.getItem("RC_NOMEUSR"),
                assinatura: base64ImageData.replace(/^data:application\/octet-stream;base64,/, '')
            };
    
            // Enviar a requisição POST com fetch
            const response = await fetch(`${window.url_API}RegistraAssinatura?HASH=${sessionStorage.getItem('RC_HASH') + Math.random()}&ALIAS=${window.alias_RC}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("RC_TOKEN")}`
                },
                body: JSON.stringify(params)
            });
    
            if (!response.ok) {
                throw new Error('Erro na requisição');
            }
    
            setRefreshExibirAssinatura(prevState => !prevState);
            const data = await response.json();
            console.log('Resposta da requisição:', data);
        } catch (error) {
            console.error('Erro ao enviar requisição:', error);
        }
    };
    

    return (
        <div id='container' >
            <hr />
            <SignaturePad ref={sigCanvas} canvasProps={{ width: 500, height: 200, className: 'sigCanvas', className: "signatureCanvas" }} />
            <hr />
            {/* Button to trigger save canvas image */}

            <Button variant='success' onClick={save}>Salvar Desenho</Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant='warning' onClick={clear}>Limpar Desenho</Button>
            &nbsp;&nbsp;&nbsp;
            <br />
            <br />
            <div id='container'>
                <div id='droparea' ref={dropAreaEle => dropAreaRef = dropAreaEle}>
                    Ou carregue uma nova imagem:
                </div>
                <div id='uploadfile'>
                    <UploaderComponent
                        autoUpload={true}
                        id="UploadFiles"
                        locale="pt-BR"
                        ref={UploadComponentRef}
                        removing={imageUploading}
                        uploading={imageUploading}
                        asyncSettings={asyncSettings}
                        buttons={{ browse: "Escolher Imagem" }}
                        allowedExtensions='.png'
                        maxFileSize={3000000}
                    />
                </div>
            </div>
            {/* <DialogComponent id="defaultdialog" showCloseIcon={true} animationSettings={animationSettings} ref={dialog => dialogInstance = dialog} height='auto' header='Base64 of the signature' visible={false} width='80%' /> */}
            <ExibirAssinatura show={show} refresh={refreshExibirAssinatura}/>
        </div>
    );
}