import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Link, useHistory } from 'react-router-dom';
import { formataDinheiro } from '../../util/formatacao';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ModalMedicao from '../../pages/detalheMovimento/components/modalMedicao';

import Rodape from '../../components/rodape';
import CardDeckAprovadores from './components/cardDeckAprovadores';
import CardDeckAbastecimentos from './components/cardDeckAbastecimentos';
import FormDetalheMovimento from './components/formDetalheMovimento';
import Navbar from '../../components/navbar';
import Loading from '../../components/loading';
import CardDetalhes from '../../components/cardDetalhes';
import { MensagemErro } from '../../components/alerts';
import BtnDetalheMovRodape from './components/btnDetalheMovRodape';
import ImpressQuadroComparativo from '../../components/impressQuadroComparativo';
import './style.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import DataGrid from '../../components/datagrid';
import Icone from '../../components/Icone';

library.add(fas);


export default function DetalheMovimento(props) {
    const params = useParams();
    const history = useHistory();

    const [dados, setDados] = useState('');
    const [itens, setItens] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('');

    //Define as colunas da tabela de itens
    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'nomefantasia',
            text: 'Descrição do Item',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'descccusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'idobjoficina',
            text: 'Obj. Manutenção',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'quantidade',
            text: 'Quant.',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => value.toFixed(2).replace('.', ','),
            sort: true,
        },
        {
            dataField: 'codund',
            text: 'Und.',
            headerAlign: 'center',
            align: 'center',
            sort: true,
        },
        {
            dataField: 'precounitario',
            text: 'Valor Unitário',
            headerAlign: 'right',
            align: 'right',
            visibility: false,
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'valortotalitem',
            text: 'Valor Total',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
    ]

    const movAtual = {
        idMov: params.idmov,
        idAprov: params.idaprovacao,
        stats: params.statusMov
    };

    console.log(movAtual);

    useEffect(() => {
        async function getDados() {
            setCarregando(true);

            let config = {
                headers: {
                    'IDMOV': props.match.params.idmov,
                    'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                }
            };

            try {
                const response = await api.get('/DetalheMovimento', config)
                setDados(response.data);

                let listaItens = response.data.itemmov !== undefined ? response.data.itemmov.map((item, index) => {
                    return ({
                        id: index,
                        botoes: <Link to={`/DetalheItem/${item.idmov}/${response.data.idaprovacao}/${item.nseqitmmov}/${props.match.params.statusMov}/0`}
                            className="btn btn-primary btn-sm btn-grid mr-1" data-placement="right" title="Detalhes do Item">
                            <FontAwesomeIcon icon="list-alt" />
                        </Link>,
                        nomefantasia: item.nomefantasia ?? '-',
                        descccusto: item.descccusto ?? '-',
                        idobjoficina: item.idobjoficina ?? '-',
                        quantidade: item.quantidade ?? 0,
                        codund: item.codund ?? '',
                        precounitario: item.precounitario ?? 0,
                        valortotalitem: item.valortotalitem ?? 0,
                    });
                }) : '';

                setItens(listaItens);

            }
            catch (err) {
                setErro(`> ${err}`);
            }
            finally {
                setCarregando(false);
            }
        }
        getDados();
    }, [props])

    let movimentos = JSON.parse(sessionStorage.getItem("idsMovimentos"));

    var indice;
    var idmov = parseInt(props.match.params.idmov);
    var idaprovacao = props.match.params.idaprovacao;
    var statusMov = props.match.params.statusMov;

    var tamanho = movimentos.length;

    //Movimentos é um array de arrays, então eu preciso descobrir em qual subarray está o ID do movimento que estou atualmente
    //Para poder implementar a lógica de prox movimento e movimento anterior

    for (let i = 0; i < tamanho; i++) {
        const subarray = movimentos[i];
        for (let j = 0; j < subarray.length; j++) {

            if (subarray[j] == idmov) {
                indice = i;
            }
        }
    }

    const proxMov = () => {
        if (indice == tamanho - 1) {
            history.push("/detalheMovimento/" + movimentos[0][0] + "/" + movimentos[0][1] + "/" + movimentos[0][2]);
        } else {
            var prox = indice + 1;
            history.push("/detalheMovimento/" + movimentos[prox][0] + "/" + movimentos[prox][1] + "/" + movimentos[prox][2]);
        }
    }

    const movAnterior = () => {
        if (indice == 0) {
            var anterior = tamanho - 1;
            history.push("/detalheMovimento/" + movimentos[anterior][0] + "/" + movimentos[anterior][1] + "/" + movimentos[anterior][2]);
        } else {
            var anterior = indice - 1;
            history.push("/detalheMovimento/" + movimentos[anterior][0] + "/" + movimentos[anterior][1] + "/" + movimentos[anterior][2]);
        }
    }

    return (
        <div>
            <Navbar />

            <CardDetalhes titulo="Detalhes do Movimento" subtitulo="Verifique todos os dados abaixo."
                btnImprimeVisible={true} dados={dados} disabled={carregando} idmov={props.match.params.idmov} exibeBotao={true}>
                <MensagemErro mensagem={erro} />
                <br />
                {carregando ?
                    <Loading />
                    :
                    <>
                        <FormDetalheMovimento
                            numeromov={dados.numeromov ?? '-'}
                            nomefantasia={dados.nomefantasia ?? '-'}
                            dataemissao={dados.dataemissao ?? '-'}
                            tipomov={dados.codtmv + " - " + dados.descTmv ?? '-'}
                            nomecomprador={dados.nomeVendedor ?? '-'}
                            idmov={dados.idmov ?? '-'}
                            condicaopagamento={dados.descCpg ?? '-'}
                            quantidadeitens={dados.itemmov === undefined ? '' : dados.itemmov.length}
                            valorbruto={dados.valorbruto}
                            valorliquido={dados.valorliquido}
                            valorfrete={dados.valorfrete}
                            observacao={dados.observacao ?? ''}
                            historico={dados.historico ?? ''}
                        />

                        <CardDeckAprovadores
                            idmov={dados.idmov}
                            idaprovacao={props.match.params.idaprovacao}
                        />

                        <hr />

                        {dados.chaveacessonfe &&
                            <>
                                <CardDeckAbastecimentos
                                    idmov={dados.idmov}
                                    chaveAcesso={dados.chaveacessonfe}
                                />
                                <hr />
                            </>
                        }

                        <DataGrid
                            identificador="id"
                            colunas={colunas}
                            dados={itens ?? ''}
                            paginacao={true}
                        />


                        <div className="text-right">
                            <hr />

                            <BtnDetalheMovRodape
                                status={props.match.params.statusMov}
                                idmov={dados.idmov}
                                numeromov={dados.numeromov}
                                nomefantasia={dados.nomefantasia}
                                valorliquido={dados.valorliquido}
                                codtmv={dados.codtmv}
                                usuariomestre={dados.usuariomestre}
                                dependencia={dados.dependencia}
                                idaprovacao={dados.idaprovacao}
                                sequencial={dados.sequencial}
                                codusuario={dados.codusuario}
                                nomeVendedor={dados.nomeVendedor} />
                        </div>
                    </>
                }
            </CardDetalhes>
            <Container>
                <hr />
                <Row className="text-center">
                    <Col sm={6}>
                        <Button onClick={movAnterior} variant="info">
                            <Icone icon="chevron-left" /> <b> Movimento Anterior</b>
                        </Button>
                    </Col>

                    <Col sm={6}>
                        <Button onClick={proxMov} variant="info">
                            <b>Próximo Movimento </b> <Icone icon="chevron-right" />
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Rodape />
        </div>
    );
}