import React from 'react';
import { Form, Col, ButtonGroup } from 'react-bootstrap';
import { formataData, formataDataHora, formataDinheiro } from '../../../../util/formatacao';
import ModalImpressao from '../../../../components/modalImpressao';

export default function FormDetalheMov(props) {
    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputIdMov">Numero NF</Form.Label>
                    <Form.Control readOnly id="inputIdMov" value={props.numeromov} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputNomeFantasia">Razão Social</Form.Label>
                    <Form.Control readOnly id="inputNomeFantasia" value={props.nomefantasia} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataEmissao">Data de Emissão</Form.Label>
                    <Form.Control readOnly id="inputDataEmissao" value={formataData(props.dataemissao)} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputIdMov">Id. Movimento</Form.Label>
                    <Form.Control readOnly id="inputIdMov" value={props.idmov} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputTipoMovimento">Tipo de Movimento</Form.Label>
                    <Form.Control readOnly id="inputTipoMovimento" value={props.tipomov} />
                </Form.Group>

            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md={3}>
                    <Form.Label htmlFor="inputUsuarioRecebimentoFisico">Usuário Entrada Física</Form.Label>
                    <Form.Control readOnly id="inputUsuarioRecebimentoFisico" value={props.criadopor} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataRecebimentoFisico">Data Entrada Física</Form.Label>
                    <Form.Control readOnly id="inputDataRecebimentoFisico" value={formataDataHora(props.criadoem)} />
                </Form.Group>

                <Form.Group as={Col} md={3}>
                    <Form.Label htmlFor="inputUsuarioRecebimentoFiscal">Usuário Entrada Fiscal</Form.Label>
                    <Form.Control readOnly id="inputUsuarioRecebimentoFiscal" value={props.modificadopor} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataRecebimentoFiscal">Data Entrada Fiscal</Form.Label>
                    <Form.Control readOnly id="inputDataRecebimentoFiscal" value={formataDataHora(props.modificadoem)} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} >
                    <Form.Label htmlFor="inputAprovPedido">Aprovador do movimento 1</Form.Label>
                    <Form.Control readOnly id="inputAprovPedido" value={props.useraprovacao1} />
                </Form.Group>
                
                <Form.Group as={Col} >
                    <Form.Label htmlFor="inputDataAprovPedido">Aprovador do movimento 2</Form.Label>
                    <Form.Control readOnly id="inputDataAprovPedido" value={props.useraprovacao2} />
                </Form.Group>

                <Form.Group as={Col} >
                    <Form.Label htmlFor="inputDataAprovPedido">Aprovador do movimento 3</Form.Label>
                    <Form.Control readOnly id="inputDataAprovPedido"value={props.useraprovacao3} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputCondPag">Condição de Pag.</Form.Label>
                    <Form.Control readOnly id="inputCondPag" value={props.descCpg} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrBruto">Valor Bruto</Form.Label>
                    <Form.Control readOnly id="inputVlrBruto"
                        value={formataDinheiro(props.valorbruto ?? 0)} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrFrete">Valor do Frete</Form.Label>
                    <Form.Control readOnly id="inputVlrFrete" value={formataDinheiro(props.valorfrete ?? 0)} />

                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrLiquido">Valor Líquido</Form.Label>
                    <Form.Control readOnly id="inputVlrLiquido" value={formataDinheiro(props.valorliquido ?? 0)} />
                </Form.Group>

                {/* <ButtonGroup className="pt-4 mt-2" >
                    <ModalImpressao idmov={props.idmov}
                        entradaFisica={props.criadopor + ' - ' + formataDataHora(props.criadoem)}
                        entradaFiscal={props.modificadopor + ' - ' + formataDataHora(props.modificadoem)}
                        useraprovacao1={props.useraprovacao1}
                        useraprovacao2={props.useraprovacao2}
                        useraprovacao3={props.useraprovacao3}
                        visible={true} />
                </ButtonGroup> */}
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputObservacao">Observações</Form.Label>
                    <Form.Control type="text" readOnly id="inputObservacao" value={props.observacao} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputHistorico">Histórico</Form.Label>
                    <Form.Control as="textarea" readOnly rows={5} id="inputHistorico" value={props.historico} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
}