import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import logoEmpresa from '../../assets/img/logoEmpresa.png';
import LabelVersao from '../../components/labelVersao';
import FormLoginNovo from '../../components/formLoginNovo';
import dadosAplicacao from '../../../package.json';
import './style.css';


export default function Login(props) {
    if (localStorage.getItem('AppVersion')) {
        if (localStorage.getItem('AppVersion') != dadosAplicacao.version) {
            localStorage.setItem('AppVersion', dadosAplicacao.version);
            window.location.reload(true);
        }
    } else {
        localStorage.setItem('AppVersion', dadosAplicacao.version);
        window.location.reload(true);
    }
    return (
        <Container fluid className="form-login">
            <Row className="no-gutter">
                <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
                    <div className="titulo-login">
                        <h4 className="h4-titulo">Bem-vindo ao</h4>
                        <h2 className="h2-titulo"><strong>PORTAL</strong> RC</h2>
                    </div>
                    <Container fluid className="text-center rodape-imagem">
                        <p className="mr-4">Copyright &copy; 2020 -
                            RC Consultoria em Soluções Inteligentes. Todos os direitos reservados.</p>
                    </Container>
                </div>
                <Col md={8} lg={6}>
                    <div className="login d-flex align-items-center py-4">
                        <Container>
                            <Row>
                                <Col md={9} lg={6} className="mx-auto">

                                    <Image src={logoEmpresa} id="logo-empresa" className="align-center" />

                                    <FormLoginNovo
                                        historico={props.history} />

                                    <div className="text-right">
                                        <LabelVersao />
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container >
    );
}