import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';

library.add(fas);


export default function BtnRodape(props) {

    const disable = (props.status === "Reprovado" || props.status === "Aprovado");
    const history = useHistory();
    async function aprovaMov() {
        let config = {
            headers: {
                'IDMOV': props.idmov,
                'CODTMV': props.codtmv,
                'CODUSUARIOAPROVACAO': sessionStorage.getItem('RC_USR'),
                'CODUSUARIOMESTRE': props.usuariomestre,
                'DEPENDENCIA': props.dependencia,
                'IDAPROVACAO': props.idaprovacao,
                'SEQUENCIAL': props.sequencial,
                'CODUSUARIOORIGEM': props.codusuario,
                'NOME_VENDEDOR': props.nomeVendedor
            }
        }

        try {

            //faz a requisição
            const response = await api.get('/AprovacaoMovimentoObras', config);

            //verifica se teve sucesso
            if (response.data.CODIGO === "1") {
                // As linhas abaixo perterciam a função de pular para o próximo movimento ao finalizar a aprovação do movimento
                // Aqui, eu pego o array de movimentos salvo na sessão, removo o ID do movimento atual e pulo para o próximo.
                // Resolvi deixar as linhas comentadas, pois sinto que isso ainda poderá ser útil (y)


                // const { match } = this.props;
                // const id = match.params.id;

                // let movimentos = JSON.parse(sessionStorage.getItem("idsMovimentos"));
                // const movAtual = this.props.match.params.idmov;

                // const innerArrayIndex = movimentos.findIndex(innerArray => innerArray.includes(parseInt(movAtual)));

                // if (innerArrayIndex !== -1) {
                //     // Remove o array interno usando o método splice
                //     movimentos.splice(innerArrayIndex, 1);
                // }

                // sessionStorage.setItem("idsMovimentos", JSON.stringify(movimentos));

                // if (movimentos.length === 0) {

                history.push("/notaFiscal");
                // } else {
                //     RedirectTo('/detalheMovimento/' + movimentos[0][0] + "/" + movimentos[0][1] + "/" + movimentos[0][2], this.props.history);
                // }

            }
            else if (response.data.CODIGO === '2') {
                props.setValidando(false);
                props.setBtnAprovaDisabled(true);
                props.setWarning(response.data.DESCRICAO);
            }

            else if (response.data.CODIGO === '0') {
                props.setValidando(false);
                props.setBtnAprovaDisabled(true);
                props.setErro("Erro no banco de dados! Entre em contato com o suporte.");
            }
            else if (response.status != 200) {
                props.setValidando(false);
                props.setBtnAprovaDisabled(true);
                props.setErro("Erro ao efetuar aprovação, tente novamente mais tarde.");
            }
            else {
                props.setValidando(false);
                props.setBtnAprovaDisabled(true);
                props.setErro(response.data.DESCRICAO);
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <>
            {disable === false ?
                <>
                    <Button onClick={aprovaMov}
                        className="btn btn-success mr-1 btn-grid" data-placement="right" title="Aprovar Movimento" >
                        <FontAwesomeIcon icon="check" className="mr-2" />
                        Aprovar
                    </Button>


                    <Link to={`/reprovaMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido}/${props.usuariomestre}/${props.dependencia}/${props.idaprovacao}/${props.codusuario}/1`}
                        className="btn btn-danger btn-grid mr-1" data-placement="right" title="Reprovar Movimento" >
                        <FontAwesomeIcon icon="times-circle" className="mr-2" />
                        Reprovar
                    </Link>

                    <Link to={`/reavaliarMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido}/${props.idaprovacao}/1`}
                        className="btn btn-warning btn-grid mr-1" data-placement="right" title="Reavaliar Movimento" >
                        <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                        Reavaliar
                    </Link>
                </>

                :

                <>
                    <Button variant="success" className="mr-1" disabled>
                        <FontAwesomeIcon icon="check" className="mr-2" />
                        Aprovar
                    </Button>

                    <Button variant="danger" className="mr-1" disabled>
                        <FontAwesomeIcon icon="times-circle" className="mr-2" />
                        Reprovar
                    </Button>

                    <Button variant="warning" className="mr-1" disabled>
                        <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                        Reavaliar
                    </Button>
                </>
            }
            {props.financeiro == 0 ?
                <Link to='/notaFiscal' className="btn btn-primary btn-grid mr-1" data-placement="left" title="Volta para a visão de movimentos">
                    <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                    Voltar
                </Link>
                :
                <Link to='/financeiro' className="btn btn-primary btn-grid mr-1" data-placement="left" title="Volta para a visão de movimentos">
                    <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                    Voltar
                </Link>
            }
        </>
    )
}
