import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Form, FormGroup, Button, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { formataData } from '../../../util/formatacao';

// import { formataDinheiro } from '../../util/formatacao';
import CardDetalhes from '../../../components/cardDetalhes';
import Rodape from '../../../components/rodape';
import Navbar from '../../../components/navbar';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import BtnRodape from './components/btnRodape';
import { useState } from 'react';
import { useEffect } from 'react';
import api from '../../../services/api';

library.add(fas);


function AprovaLancNF(props) {
    const [state, setState] = useState();
    const [validando, setValidando] = useState();
    const [warning, setWarning] = useState();
    const [btnAprovaDisabled, setBtnAprovaDisabled] = useState();
    const [notaFiscal, setNotaFiscal] = useState('');
    const [dados, setDados] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [erro, setErro] = useState('');
    const [carregando, setCarregando] = useState(true);

    console.log(props);


    useEffect(() => {
        async function getDados() {
            setCarregando(true);

            let config = {
                headers: {
                    'IDMOV': props.match.params.idmov
                }
            }

            try {

                //faz a requisição
                const response = await api.get('/DetalheMovimentoAprovadores', config);
                setDados(response.data);

            }
            catch (err) {
                setValidando(false);
                setBtnAprovaDisabled(false);
                setErro("Erro ao efetuar aprovação, tente novamente mais tarde.")
                console.log(err);
            }finally{
                setCarregando(false);
            }
        }

        async function getNotaFiscal() {

            let config = {
                headers: {
                    'IDMOV': props.match.params.idmov
                }
            }

            try {

                //faz a requisição
                const response = await api.get('/NotaFiscalFornecedor', config);
                setNotaFiscal(response.data);

            }
            catch (err) {
                setValidando(false);
                setBtnAprovaDisabled(false);
                setErro("Erro ao buscar a Nota Fiscal, tente novamente mais tarde.")
                console.log(err);
            }
        }


        async function getDadosLan(idlan) {
            setValidando(true)
            setErro('')

            let config = {
                headers: {
                    'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                    'IDLAN': props.match.params.idlan,
                }
            }

            try {
                const response = await api.get('/DetalheLancamento', config);

                const dados = response.data;
                setDetalhes(dados);
            }
            catch (err) {
                setValidando(false);
                setBtnAprovaDisabled(false);
                setErro("Erro ao buscar a os dados do lançamento, tente novamente mais tarde.")
                console.log(err);
            }
        }

        getDados();
        getNotaFiscal();
        getDadosLan();
    }, [props]);

    const marginInput = {
        marginBottom: "0px"
    }

    const boxNf = {
        margin: "10px 0px",
        textAlign: "center",
        border: "3px solid #c1c1c1",
        height: "110vh",
        width: "100%",
        fontWeight: "bold",
        overflowY: "scroll"
    }

    const titBoxes = {
        fontSize: "14px"
    }

    const boxInputs = {
        width: "100%",
        padding: "0px",
        marginBottom: "15px"
    }

    const txtFornecedor = {
        textDecoration: "underline",
        color: "#363636"
    }

    const centroCusto = {
        margin: "10px 0px",
        color: "#616161",
        textAlign: "center",
        fontSize: "16px"
    }

    const rowInput = {
        paddingBottom: "10px"
    }

    const marginTop = {
        marginTop: '32px'
    }

    let financeiro = props.match.params.financeiro;

    const ordenaDadosPorData = (dados) => {
        // Dividir a string em uma matriz de aprovações
        const approvalsArray = dados.split(' | ');

        // Extrair e analisar as datas de aprovação
        const approvalObjects = approvalsArray.map(approval => {
            const [name, dateStr] = approval.split(' Em: ');
            const [day, month, year, time] = dateStr.split(/[/\s]/);
            const date = new Date(`${year}-${month}-${day}T${time}`);
            return { name, date };
        });
        // Ordenar os dados de aprovação por data
        approvalObjects.sort((a, b) => a.date - b.date);

        let dadosTratados = "";
        approvalObjects.map((approval, index) => (
            dadosTratados = dadosTratados + approval.name + " - " + approval.date.toLocaleString().slice(0, -3) + "h\n"
        ));

        return dadosTratados;
    }


    const tituloCardDetalhes = () => {
        let tituloCardDetalhes;

        if (dados.numeronf && dados.dataemissaonf) {
            tituloCardDetalhes = detalhes.tipoDocto + ": " + dados.numeronf;

        } else {
            tituloCardDetalhes = "DADOS NÃO ENCONTRADOS";
        }

        return tituloCardDetalhes;
    }

    let valorNF = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(detalhes.valor);

    console.log(dados.valorsaldopedido);

    return (
        <div>
            <Navbar />
            {carregando ?
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <center >
                        <h4 style={{ color: "#007bff" }}>Carregando Informações...</h4>
                        <Spinner textAlign='center' animation="border" variant="primary" />
                    </center>
                    <br />
                </>
                :
                <CardDetalhes
                    titulo={"Aprovação do: " + tituloCardDetalhes()}
                    exibe={true}
                    valor={valorNF.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    valorTotal={dados.valortotalpedido ? dados.valortotalpedido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}
                    valorSaldo={dados.valorsaldopedido ? dados.valorsaldopedido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}
                    btnImprimeVisible='false'
                >

                    {props.erro && <div className="alert alert-danger" role="alert">Erro:
                        <FontAwesomeIcon className="mr-2" icon="times" />
                        {props.erro}
                    </div>}

                    {props.warning && <div className="alert alert-warning" role="alert">
                        <FontAwesomeIcon className="mr-2" icon="exclamation-triangle" />
                        {props.warning}
                    </div>}
                    <hr />
                    <Form>
                        <Row>

                            <Col lg={4} style={rowInput}>
                                <Form.Label htmlFor="inputFornecedor"><b>Fornecedor:</b></Form.Label>
                                <Form.Control readOnly id="inputFornecedor" value={dados.nomefornecedor ? dados.nomefornecedor : "Sem dados"} />
                            </Col>

                            <Col lg={2} style={rowInput}>
                                <Form.Label htmlFor="inputDataEmissao"><b>Emissão:</b></Form.Label>
                                <Form.Control readOnly id="inputDataEmissao" value={dados.dataemissaonf ? formataData(dados.dataemissaonf) : "Nenhum registro"} />
                            </Col>

                            <Col lg={2} style={rowInput}>
                                <Form.Label htmlFor="inputPedido"><b>Pedido:</b></Form.Label>
                                <Form.Control readOnly id="inputPedido" value={dados.numeropedido ? dados.numeropedido : "Nenhum registro"} />
                            </Col>

                            <Col lg={4} style={rowInput}>
                                <Form.Label htmlFor="inputTipoPedido"><b>Tipo do Pedido:</b></Form.Label>
                                <Form.Control readOnly id="inputTipoPedido" value={dados.tipopedido ? dados.tipopedido : "Nenhum registro"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} style={rowInput}>
                                <Form.Label htmlFor="inputSolicitante"><b>Solicitante:</b></Form.Label>
                                <Form.Control readOnly id="inputSolicitante" value={dados.solicitantes ? dados.solicitantes : "Dados Insuficentes"} />
                            </Col>

                            <Col lg={6} style={rowInput}>
                                <Form.Label htmlFor="inputComprador"><b>Comprador:</b></Form.Label>
                                <Form.Control readOnly id="inputComprador" value={dados.compradores ? dados.compradores : "Sem compradores"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} style={rowInput}>
                                <Form.Label htmlFor="inputRecebedorFisico"><b>Rec. Físico:</b></Form.Label>
                                <Form.Control readOnly id="inputRecebedorFisico" value={dados.recebedorfisico ? dados.recebedorfisico : "Dados não encontrados"} />
                            </Col>


                            <Col lg={6} style={rowInput}>
                                <Form.Label htmlFor="inputRecebedorFiscal"><b>Rec. Fiscal:</b></Form.Label>
                                <Form.Control readOnly id="inputRecebedorFiscal" value={dados.recebedorfiscal ? dados.recebedorfiscal : "Dados não encontrados"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={12} style={boxInputs}>
                                <Col>
                                    <h4>Aprovação Pedido:</h4>
                                </Col>

                                <Col style={marginInput}>
                                    <Form.Control as="textarea" rows={4} readOnly id="inputAprovador1ped" value={dados.aprovadorespedido ? ordenaDadosPorData(dados.aprovadorespedido) : "Sem aprovadores para o pedido"} />
                                </Col>
                            </Col>

                            <Col lg={4} md={12} style={boxInputs}>
                                <Col>
                                    <h4>Aprovação Nota Fiscal:</h4>
                                </Col>

                                <Col style={marginInput}>
                                    <Form.Control as="textarea" rows={4} readOnly id="inputAprovador1nf" value={dados.aprovadoresnota ? ordenaDadosPorData(dados.aprovadoresnota) : "Sem aprovadores para a NF"} />
                                </Col>
                            </Col>

                            <Col lg={4} md={12} style={boxInputs}>
                                <Col>
                                    <h4>Aprovação Financeiro:</h4>
                                </Col>

                                <Col style={marginInput}>
                                    <Form.Control as="textarea" rows={4} readOnly id="inputAprovador1nf" value={dados.aprovadoresfinanceiro ? ordenaDadosPorData(dados.aprovadoresfinanceiro) : "Sem aprovadores Financeiros"} />
                                </Col>
                            </Col>
                        </Row>
                        <hr />
                        <Row style={centroCusto}>
                            <p>Centro de custo: {props.match.params.centroCusto ? props.match.params.centroCusto.replaceAll("|", "/") : "NENHUM VALOR ENCONTRADO!"}</p>
                        </Row>
                        <Row style={boxNf}>
                            <embed src={"data:application/pdf;base64," + notaFiscal.arquivo + "#view=FitH"} type="application/pdf" width="100%" heigh
                                ="100%" />
                        </Row>
                        <br />
                        <br />
                        <hr />
                        <div className="text-right">
                            <BtnRodape
                                financeiro={financeiro}
                                status={detalhes.statusmovimento}
                                idmov={detalhes.idmov}
                                numeromov={detalhes.numeromov}
                                nomefantasia={detalhes.nomefantasia}
                                valorliquido={detalhes.valorliquido}
                                codtmv={detalhes.codtmv}
                                usuariomestre={detalhes.usuariomestre}
                                dependencia={detalhes.dependencia}
                                idaprovacao={detalhes.idaprovacao}
                                sequencial={detalhes.sequencial}
                                codusuario={detalhes.codusuario}
                                nomeVendedor={detalhes.nomeVendedor}
                                idlan={detalhes.idlan}
                            />
                        </div>
                    </Form>
                </CardDetalhes>
            }
            <Rodape />

        </div >
    );

}

export default AprovaLancNF;