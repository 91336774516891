import React, { useState } from 'react';
import api from '../../services/api';
import { RedirectTo } from '../../util/utilLibrary';
import { Link } from 'react-router-dom';

import { Form, Button, Spinner, FormGroup } from 'react-bootstrap'
import Navbar from '../../components/navbar';
import Rodape from '../../components/rodape';
import CardDetalhes from '../../components/cardDetalhes';
import FormDadosConfirmacao from '../../components/formDadosConfirmacao';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function ReavaliarMovimento(props) {
    const [carregando, setCarregando] = useState(false);
    const [motivo, setMotivo] = useState('');
    const [erro, setErro] = useState('');


    async function handleReavaliarMovimento(e) {
        e.preventDefault();

        setCarregando(true);

        let body = {
            'MOVIMENTO': [
                {
                    'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                    'IDMOV': props.match.params.idmov,
                    'DESCMOTIVO': motivo,
                    'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                    'IDAPROVACAO': props.match.params.idaprovacao,
                }
            ]
        }


        try {
            const response = await api.post('/ReavaliarMovimentoObras', body);
            //verifica se teve sucesso
            if (response.data.CODIGO === "1") {
                if (props.match.params.notaFiscal == 0) {
                    RedirectTo('/movimentos', props.history);
                } else {
                    RedirectTo('/notaFiscal', props.history);
                }
            }
            else {
                setCarregando(false);
                setErro(response.data.DESCRICAO);
            }
        }
        catch (err) {
            setErro("Erro ao processar solicitação!");
        }
        finally {
            setCarregando(false);
        }
    }


    return (
        <>
            <Navbar />
            <CardDetalhes titulo="Reavaliar Movimento" subtitulo="Verifique se todos os dados abaixo estão corretos!"
                btnImprimeVisible={false} >
                {erro &&
                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon="times" className="mr-2" />
                        {erro}
                    </div>}
                <FormDadosConfirmacao
                    nummov={props.match.params.numeromov}
                    nomefantasia={props.match.params.nomefantasia}
                    valorliquido={parseFloat(props.match.params.valor)}
                />

                <Form onSubmit={handleReavaliarMovimento}>
                    <FormGroup className="mt-4">
                        <Form.Group className="mt-4">
                            <Form.Label htmlFor="textObservacao">Motivo</Form.Label>
                            <Form.Control as="textarea" id="textObservacao" rows="5"
                                onChange={e => setMotivo(e.target.value)} required>
                            </Form.Control>
                        </Form.Group>
                    </FormGroup>

                    <div className="text-right">
                        {props.match.params.notaFiscal == 1 ?
                            <Link to='/notaFiscal' className="btn btn-light mr-2">
                                Cancelar
                            </Link>

                            :

                            <Link to='/movimentos' className="btn btn-light mr-2">
                                Cancelar
                            </Link>
                        }
                        <Button type="submit" variant="warning" disabled={carregando}>
                            {carregando ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        className="mr-2"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Processando...</>
                                :
                                <>
                                    <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                                    Reavaliar Movimento
                                </>
                            }
                        </Button>
                    </div>
                </Form>
            </CardDetalhes>
            <Rodape />
        </>
    );
}