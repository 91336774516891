import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";

//pages 
import Login from './pages/login';
import DetalheMovimento from './pages/detalheMovimento';
import DetalheItemMovimento from './pages/detalheItensMovimento';
import AprovaMovimento from './pages/aprovaMovimento';
import ReprovaMovimento from './pages/reprovaMovimento';
import AprovaNotaFiscal from './pages/aprovaNotaFiscal';
import SelecaoColigada from './pages/selecaoColigada';
import VisaoMovimentos from './pages/visaoAprovacao';
import VisaoNotaFiscal from './pages/visaoNotaFiscal';
import Menu from './pages/menu';
import ReavaliarMovimento from './pages/reavaliarMovimento'
import Erro404 from './pages/erros/erro404';
import VisaoSolicitacaoCompras from "./pages/visaoSolicitacaoCompras";
import VisaoFinanceiro from './pages/visaoFinanceiro';
import VisaoKPI from './pages/Kpi/visao';
//import ListagemRelatoriosLanc from './pages/listagemRelatorioLanc';
import DetalheLancamento from './pages/detalheLancamento';
import VisaoProgramacao from "./pages/programacao/visao";
import DetalheNotaFiscal from "./pages/detalheNotaFiscal";
import AprovaLancNF from "./pages/visaoFinanceiro/aprovaLancNF";
//import RelatorioLancamentos from "./pages/relatoriosLancamentos";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
        }
    />
);

const Routes = () => (
    <HashRouter basename={window.pasta}>
        <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute exact path="/app" component={Menu} />
            <PrivateRoute exact path="/movimentos" component={VisaoMovimentos} />
            <PrivateRoute exact path="/compras" component={VisaoSolicitacaoCompras} />
            <PrivateRoute exact path="/financeiro" component={VisaoFinanceiro} />
            <PrivateRoute exact path="/aprovaLancNF/:idmov/:financeiro/:centroCusto/:idlan" component={AprovaLancNF} />
            <PrivateRoute exact path="/programacao" component={VisaoProgramacao}/>
            <PrivateRoute exact path="/selecionaEmpresa/:primeiroAcesso" component={SelecaoColigada} />
            <PrivateRoute exact path="/detalheMovimento/:idmov/:idaprovacao/:statusMov" component={DetalheMovimento} />
            <PrivateRoute exact path="/DetalheItem/:idmov/:idaprovacao/:nseq/:statusMov/:notafiscal" component={DetalheItemMovimento} />
            <PrivateRoute exact path="/aprovaMovimento/:idmov/:numeromov/:nomefantasia/:valor/:codtmv/:usuariomestre/:dependencia/:idaprovacao/:sequencial/:codusuario/:nomevendedor/:notafiscal" component={AprovaMovimento} />
            <PrivateRoute exact path="/reprovaMovimento/:idmov/:numeromov/:nomefantasia/:valor/:usuariomestre/:dependencia/:idaprovacao/:codorigem/:notaFiscal" component={ReprovaMovimento} />
            <PrivateRoute exact path="/reavaliarMovimento/:idmov/:numeromov/:nomefantasia/:valor/:idaprovacao/:notaFiscal" component={ReavaliarMovimento} />
            <PrivateRoute exact path="/detalheLancamento/:idlan" component={DetalheLancamento} />
            <PrivateRoute exact path="/Kpi" component={VisaoKPI} />
            <PrivateRoute exact path="/notaFiscal" component={VisaoNotaFiscal} />
            <PrivateRoute exact path="/aprovaNotaFiscal/:idmov/:financeiro/:centroCusto/:valor/:desc" component={AprovaNotaFiscal} />
            <PrivateRoute exact path="/detalheNotaFiscal/:idmov/:idaprovacao/:statusMov" component={DetalheNotaFiscal} />
            <Route exact path="*" component={Erro404} />
        </Switch>
    </HashRouter>
);

export default Routes;


// <PrivateRoute exact path="/relatorios" component={RelatorioLancamentos} />
//<PrivateRoute exact path="/listagemRelatorios" component={ListagemRelatoriosLanc} />