import React, { useEffect } from 'react';
import { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { formataDinheiro } from '../../../../util/formatacao';

function ToastSoma(props) {
  // console.log("SOMA ==>", PercorreLancamentos());
  const [showToast, setShowToast] = useState();

  useEffect(() => {
    if (props.valorSoma > 1) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [props.valorSoma])

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'relative',
          zIndex: 1,
          height: 0
        }}
      >
        <Toast
          style={{
            backgroundColor: '#ffff8d',
            position: 'fixed',
            bottom: 3,
            right: 3,
            zIndex: 1
          }}
          show={showToast}
        >
          <Toast.Header closeButton={false} style={{ backgroundColor: '#ffc400' }}>
            {/* <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" /> */}
            <strong className="mr-auto" style={{ color: '#000' }}>Atenção! Valor total selecionado:</strong>
          </Toast.Header>
          <Toast.Body>
            <b>{formataDinheiro(props.valorSoma)}</b>
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default ToastSoma;