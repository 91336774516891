import React from 'react';
import { Link } from 'react-router-dom'

import { ButtonGroup, Button } from 'react-bootstrap'
import ModalReavaliaLanc from '../../../../components/modalReavaliaLanc';
import ModalAprovMultipla from '../../../../components/ModalAprovMultipla';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import api from '../../../../services/api';

library.add(fas);

export default function BtnVisaoLanc(props) {
    console.log("btn ---->", props);
    const disable = (props.status === "Reprovado" || props.status === "Aprovado");

    async function getRelatorio() {

        let config = {
            headers: {
                'IDMOV': this.props.idmov,
            }
        }


        try {

            this.setState({ carregando: true, mensagem: "" });


            const response = await api.get('/NotaFiscalFornecedor', config);
            this.setState({ dados: response.data.arquivo });

            switch (response.data.status) {

                case "0":
                    this.setState({ mensagem: "Sem dados de relatório", semRelatorio: true, tipoMensagem: "primary", carregando: false });
                    break;

                case "1":
                    this.setState({ dados: response.data.arquivo, tipoMensagem: "", carregando: false });
                    this.handleShow();
                    break;

                default:
                    this.setState({ mensagem: "Erro ao gerar relatório!", tipoMensagem: "danger", carregando: false });
            }

        }
        catch (err) {
            this.setState({ mensagem: 'Erro ao carregar o relatório!', tipoMensagem: "danger", carregando: false })
        }

    }
    return (
        <ButtonGroup>
            <Link to={`/detalheLancamento/${props.idlan}`}
                className="btn btn-primary btn-grid mr-2" data-placement="right" title="Detalhes do Lançamento">
                <FontAwesomeIcon icon="list-alt" />
            </Link> {" "}

            {disable === false ?
                <>
                    <ModalReavaliaLanc
                        idlan={props.idlan}
                        idaprovacao={props.idaprovacao}
                        visao={true} />


                    <Link to={`/aprovaLancNF/${props.idmov}/1/${props.centrocusto.replaceAll("/", "|")}/${props.idlan}`}
                        className="btn btn-primary btn-grid mr-2"  variant="info" data-placement="right" title="Exibir Nota Fiscal">
                        <FontAwesomeIcon icon="sticky-note" />
                    </Link>

                    <ModalAprovMultipla
                        visao={true}
                        idlanAtual={props.idlan}
                        codcoligada={props.codcoligada}
                        // codusuarioaprovacao={}
                        usuariomestre={props.usuariomestre}
                        dependencia={props.dependencia}
                        idaprovacao={props.idaprovacao}
                        sequencial={props.sequencial}
                        codusuario={props.codusuario}
                    />
                </>
                :
                <>
                    <Button variant="warning" disabled className="mr-2">
                        <FontAwesomeIcon icon="thumbs-down" />
                    </Button>

                    <Link to={`/aprovaLancNF/${props.idmov}/1/${props.centrocusto.replaceAll("/", "|")}/${props.idlan}`}
                        className="btn btn-primary btn-grid mr-2"  variant="info" data-placement="right" title="Exibir Nota Fiscal">
                        <FontAwesomeIcon icon="sticky-note" />
                    </Link>
                    
                    <Button variant="success" disabled className="mr-2">
                        <FontAwesomeIcon icon="check" />
                    </Button>
                </>
            }
        </ButtonGroup>
    );
}