import moment from 'moment'

export const getDataAtual = () => {
    return  moment().format("DD/MM/YYYY HH:mm:ss");
}

export const getData = (formato = "YYYY-MM-DD") => {
    return  moment().format(formato);
}


export const getDataMaisDias  = (dias) => {
    const data =  moment().add('days', dias)
    return data.format("YYYY-MM-DD");
}