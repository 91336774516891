import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useHistory, Redirect } from 'react-router-dom';
import useKeyPress from '../../util/useKeyPress';

import { Modal, Button, Alert } from 'react-bootstrap';
import Icone from '../Icone';
import DetalheLancamento from '../../pages/detalheLancamento';


export default function ModalAprovMultipla(props) {
    const [show, setShow] = useState(false);
    const [disabledAprov, setDisableAprov] = useState(false);
    const [mensagem, setMensagem] = useState()
    const [erro, setErro] = useState('');
    const [dados, setDados] = useState();
    const history = useHistory();

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setErro('');
        setShow(false);
        setDisableAprov(false);
    };

    let lancamentos = JSON.parse(sessionStorage.getItem("idsLancamentos"));

    //Se pressionado a tecla A, abre o modal de aprovação
    const keyShow1 = useKeyPress('a');
    const keyShow2 = useKeyPress('A');
    const keyShow3 = useKeyPress('Alt');
    useEffect(() => {
        if (((keyShow1 || keyShow2) && keyShow3) && !show && !props.visao) {
            handleShow();
        }
        // eslint-disable-next-line
    }, [keyShow1, keyShow2])

    async function GetDados(idlan) {
        setErro('')

        let config = {
            headers: {
                'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                'IDLAN': idlan,
            }
        }

        try {
            const response = await api.get('/DetalheLancamento', config);

            const dados = response.data;
            return dados;
        }
        catch (err) {
            setErro(err.toString());
        }
    }


    //Se pressionado a tecla ENTER com o modal aberto
    //faz a aprovação do lançamento
    const keyAprova = useKeyPress('Enter')
    useEffect(() => {
        if (keyAprova && show && !erro) {
            AprovaLancamento();
        }
        // eslint-disable-next-line
    }, [keyAprova])

    console.log("NODE =====>", props.lancamentos);

    useEffect(() => {
        setMensagem("Deseja realmente aprovar o(s) lançamento(s) selecionado(s) ? ")

        //se for lançamento com outros lançamentos vinculados
        if (show && !props.visao && props.lancamentos) {

            let selec = props.lancamentos.selectionContext.selected;
            if (selec.indexOf(props.idlanAtual) < 0 && !props.pagInicial) {
                setErro(`É obrigatória a seleção do lançamento atual. (Ref: ${props.idlanAtual} )`);
                setDisableAprov(true);
            }
            else {
                //zera as mensages e desbloqueia o botão quando sai do modal
                if (erro) {
                    setErro("");
                    setDisableAprov(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [show])

    async function AprovaLancamento() {
        setDisableAprov(true);
        setMensagem('Aprovando, por gentileza aguarde...')

        let selec = '';
        let multiplos = false;
        let headers = '';
        let requisicoes = {};
        let tam = 0;

        if (props.lancamentos) {
            selec = props.lancamentos.selectionContext.selected;
            tam = selec.length;
            multiplos = true;
        } else {
            headers = new Object();
            headers.idlanAtual = props.idlanAtual;
            headers.usuariomestre = props.usuariomestre;
            headers.dependencia = props.dependencia;
            headers.idaprovacao = props.idaprovacao;
            headers.sequencial = props.sequencial;
            headers.codusuario = props.codusuario;
        }

        let body = '';

        if (multiplos === false) {
            body = {
                "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"),
                "IDLAN": headers.idlanAtual,
                "CODUSUARIOAPROVACAO": sessionStorage.getItem("RC_USR"),
                "USUARIOMESTRE": headers.usuariomestre,
                "DEPENDENCIA": headers.dependencia,
                "IDAPROVACAO": headers.idaprovacao,
                "SEQUENCIAL": headers.sequencial,
                "CODUSUARIO": headers.codusuario
            };

            await api.post('/AprovarLancamento', body)
                .then((response) => {

                    if (response.data.CODIGO === '1' && props.visao === false) {
                        history.push("/financeiro");
                    }
                    else if (response.data.CODIGO === '1' && props.visao === true) {
                        window.location.reload();
                    }
                    else {
                        setErro("ERRO: " + response.data.DESCRICAO.toString());
                    }
                })
                .catch((err) => {
                    setErro(err.toString());
                })
                .finally(() => {
                    console.log("Lançamento Aprovado");
                })
        } else {
            selec = selec.map(str => JSON.parse(str));

            setShow(true);
            for (var i = 0; i < tam; i++) {
                body = {
                    "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"), //valor fixo
                    "IDLAN": selec[i].idlan,
                    "CODUSUARIOAPROVACAO": sessionStorage.getItem("RC_USR"), //valor fixo
                    "USUARIOMESTRE": selec[i].usuariomestre,
                    "DEPENDENCIA": selec[i].dependencia,
                    "IDAPROVACAO": selec[i].idaprovacao,
                    "SEQUENCIAL": selec[i].sequencial,
                    "CODUSUARIO": selec[i].codusuario
                }
                await api.post('/AprovarLancamento', body)
                    .then((response) => {
                        console.log("Aprovados");
                    })
                    .catch((err) => {
                        setErro(err.toString());
                    })
                    .finally(() => {
                        console.log("Lançamento Aprovado");
                    })
            }
        }
        if (props.visao == false) {
            history.push("/financeiro");
        } else {
            setShow(false);
            window.location.reload();
        }
    }


    return (
        <>
            <Button variant="success" onClick={handleShow} disabled={props.disabled}>
                <Icone icon="thumbs-up" />
                {props.visao === false ? ' Aprovar' : ''}
                {props.visaoGeral === true ? " Selecionados" : ""}
            </Button>


            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>Aprovação de Lançamentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {erro ?
                        <Alert variant="danger">
                            <Icone icon="times" />
                            &nbsp; {erro}
                        </Alert>
                        :
                        mensagem
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancelar
                    </Button>

                    <Button variant="success" onClick={() => AprovaLancamento()} disabled={disabledAprov}>
                        <Icone icon="thumbs-up" className="mr-2" />
                        &nbsp; Aprovar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}