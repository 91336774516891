import React, { useState } from 'react';
import api from '../../services/api';

import {
    Modal,
    Button,
    Form,
    Dropdown
} from 'react-bootstrap';
import AlertErro from '../alertErro';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import CadastrarAssinatura from './components/cadastrarAssinatura';
import ExibirAssinatura from './components/exibirAssinatura';
library.add(fas);

export default function ModalAssinatura(props) {
    const [show, setShow] = useState(false);
    const [senha, setSenha] = useState('');
    const [senhaRepetida, setSenhaRepetida] = useState('');
    const [erro, setErro] = useState('');

    const handleShow = () => setShow(true);
    const handleClose = () => {
        erro && setErro('')
        senha && setSenha('');
        senhaRepetida && setSenhaRepetida('');

        setShow(false)
    };


    function alterarSenha() {
        erro && setErro('');

        if (senha === '' || senhaRepetida === '') {
            setErro('Todos os campos devem ser preenchidos.');
        }
        else if (senha !== senhaRepetida) {
            setErro('As senhas informadas devem ser iguais.');
        }
        else {
            const json = JSON.stringify({
                usuario: sessionStorage.getItem('RC_USR_PORTAL'),
                senha: senha,
                alias: window.alias_RC
            })

            const config = {
                ALTER: [
                    { TOKEN: btoa(json) }
                ]
            }

            api.post('/AlteraSenhaGlobal', config)
                .then(res => {
                    if (res.data.CODIGO === '0') {
                        sessionStorage.setItem('RC_ALTPSW', 'F');
                        handleClose();
                    }
                    else {
                        setErro(res.data.DESCRICAO)
                    }
                })
                .catch(err => setErro(err.toString()));
        }
    }

    const buttonStyle = {
        hide: {
            visibility: 'hidden'
        },
    }

    return (
        <>
            <Dropdown.Item id='btnAlteraSenha' onClick={() => handleShow()} style={props.hideButton && buttonStyle.hide}>
                <FontAwesomeIcon icon="signature" className="mr-2" />
                Gerenciar Assinatura
            </Dropdown.Item>

            <Modal show={show} backdrop='static' onHide={() => handleClose()} keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Gerenciar Assinatura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CadastrarAssinatura show={show} setShow={setShow}/>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="light" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}