import React, { useState } from 'react';
import api from '../../../../services/api';

import { Accordion, Card, Spinner, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { formataDinheiro } from '../../../../util/formatacao';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

console.log(library)

export default function CardDeckAbastecimentos(props) {
    const [dados, setDados] = useState([]);
    const [show, setShow] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState(false);

    const colunas = [
        {
            dataField: 'idobjoficina',
            text: 'Veículo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomeproduto',
            text: 'Produto',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'codccusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'qtde',
            text: 'Quantidade',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => value.toFixed(2).replace('.', ','),
            sort: true,
        },
        {
            dataField: 'vlPrecoUnitario',
            text: 'Valor Unitário',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'valortotal',
            text: 'Valor Total',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
    ]

    async function GetDados() {
        setCarregando(true);

        if (!show) {
            setShow(true);

            let config = {
                params: {
                    'CODCOLIGADA': sessionStorage.getItem("RC_CODCOLIGADA"),
                    'ALIAS': window.alias_RC,
                    'HASH': sessionStorage.getItem("RC_HASH"),
                    'DSC_CHAVE': props.chaveAcesso,
                    'IDMOV': "",
                }
            }

            try {
                const response = await api.get('/RateioCombustivel', config);
                setDados(response.data);
            }
            catch (err) {
                setErro(true);
                console.error(err);
            }
            finally {
                setCarregando(false);
            }
        }
        else {
            setShow(false);
        }
    }

    return (
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle as={Card.Header} onClick={GetDados} eventKey="1">
                <FontAwesomeIcon icon="gas-pump" className="mr-2" />
                    Abastecimentos.
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    {carregando ?
                        <div className="text-center">
                            <Spinner className="mb-3 mt-3" animation="border" variant="primary" />
                        </div>
                        :
                        erro === false ?
                            <div className="container-fluid mt-3">
                                {dados !== undefined ?
                                    <div>
                                        <BootstrapTable
                                            keyField={"idmov"}
                                            data={dados}
                                            columns={colunas}
                                            loading={carregando}
                                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                                            hover={true}
                                            bootstrap4={true}
                                            bordered={false}
                                            overlay={overlayFactory({ spinner: true })}
                                            wrapperClasses="table-responsive"
                                            pagination={props.paginacao === true ? paginationFactory() : null}
                                        />

                                    </div> : <Alert variant="secondary">Sem Dados de Abastecimento</Alert>}
                            </div>

                            : <Alert variant="danger">Erro ao carregar os dados. Tente novamente mais tarde.</Alert>
                    }
                </Accordion.Collapse>
            </Card>
        </Accordion >
    );
}