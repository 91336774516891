import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getDataAtual } from '../../util/getData'
import { formataData, formataDinheiro } from '../../util/formatacao'

import { Container, Form, Card, Row, Button, Col, Alert } from 'react-bootstrap'
import CardTitulo from '../../components/cardTitulo';
import BadgeStatusMov from '../../components/badgeStatusMov';
import Navbar from '../../components/navbar';
import Rodape from '../../components/rodape';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BtnVisao from './components/btnVisao';
import ModalAprovMultiplaNf from '../../components/ModalAprovMultiplaNf';

import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);


export default function VisaoNotaFiscal(props) {
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState('');
    const [btnAtualizaDisable, setBtnAtualizaDisable] = useState(true);
    const [carregando, setCarregando] = useState(true);
    const [dados, setDados] = useState([]);
    const [semPedidoMae, setSemPedidoMae] = useState([]);
    const [erro, setErro] = useState('');

    const { SearchBar } = Search;

    // console.log(props);

    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'numeromov',
            text: 'Num. Movimento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => <BadgeStatusMov status={value !== undefined ? value : '-'} />,
            sort: true,
        },
        {
            dataField: 'tipomov',
            text: 'Tipo de Movimento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomefantasia',
            text: 'Nome Fantasia',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'dataemissao',
            text: 'Data Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'nomeVendedor',
            text: 'Comprador',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'emitente',
            text: 'Solicitante',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomeCcusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'valorliquido',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'codtmv',
            hidden: true,
        },
        {
            dataField: 'usuariomestre',
            hidden: true,
        },
        {
            dataField: 'dependencia',
            hidden: true,
        },
        {
            dataField: 'sequencial',
            hidden: true,
        },
        {
            dataField: 'codusuario',
            hidden: true,
        },
        {
            dataField: 'nomeVendedor',
            hidden: true,
        },
        {
            dataField: 'jsonDados',
            hidden: true,
        }
    ];

    useEffect(() => {
        sessionStorage.getItem('RC_CODCOLIGADA') === 'A' ?
            props.history.push('/SelecionaEmpresa/0') : getDados(sessionStorage.getItem('RC_STATUSVISAO'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    async function getDados(status) {
        //remove a mensagem de erro ao atualizar os dados e desabilita o btn de atualizar visao
        setErro('');
        setBtnAtualizaDisable(true);
        setCarregando(true);

        if(status == sessionStorage.getItem('RC_STATUSVISAO')){
            sessionStorage.setItem('RC_STATUSVISAO', "");
        }

        sessionStorage.setItem('RC_STATUSVISAO', status);

        let config = {
            headers: {
                'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                'STATUSAPROVACAO': status,
                'DATAINICIAL': '01/01/2000',
                'DATAFINAL': '01/01/2099',
            }
        }
        try {
            const response = await api.get('/AprovacaoMovNotaFiscal', config);
            let dadosProcessados = response.data.map((mov) => {
                let pedidoMae = mov.pedidomae === null ?
                    setSemPedidoMae((pedidoMae) => [...pedidoMae, mov.idmov]) : mov.pedidomae

                return ({
                    botoes: <BtnVisao idmov={mov.idmov}
                        desc={mov.descTmv}
                        numeromov={mov.numeromov}
                        idaprovacao={mov.idaprovacao}
                        nomefantasia={mov.nomefantasia}
                        valorliquido={mov.valorliquido}
                        codtmv={mov.codtmv}
                        usuariomestre={mov.usuariomestre}
                        dependencia={mov.dependencia}
                        sequencial={mov.sequencial}
                        codusuario={mov.codusuario}
                        nomeVendedor={mov.nomeVendedor}
                        status={mov.statusmovimento}
                        centrocusto={mov.nomeCcusto}
                    />
                    ,
                    idmov: mov.idmov,
                    idaprovacao: mov.idaprovacao,
                    statusaprovacao: mov.statusaprovacao,
                    status: mov.statusmovimento,
                    tipomov: mov.codtmv + '-' + mov.descTmv,
                    numeromov: mov.numeromov,
                    nomefantasia: mov.nomefantasia ?? '-',
                    dataemissao: mov.dataemissao,
                    nomeVendedor: mov.nomeVendedor ?? '-',
                    emitente: mov.emitente ?? '',
                    nomeCcusto: mov.nomeCcusto ?? '-',
                    valorliquido: mov.valorliquido,
                    pedidomae: pedidoMae,
                });
            });


            dadosProcessados.forEach(function (item) {
                item.jsonDados = JSON.stringify(item);
            });

            setDados(dadosProcessados);
            //Preciso passar tb o id da aprovacao

            const idsMovimentos = dadosProcessados.map(function (array, indice) {
                return [array.idmov, array.idaprovacao, array.statusaprovacao];
            }) 

            sessionStorage.setItem("idsMovimentos", JSON.stringify(idsMovimentos));

            setUltimaAtualizacao(getDataAtual());
        }
        catch (err) {
            setErro('Ocorreu um erro ao carregar os dados, tente novamente mais tarde.');
        }
        finally {
            setBtnAtualizaDisable(false);
            setCarregando(false);
        }
    }

    
    //renderização da expansão da coluna
    // const expandRow = {
    //     renderer: row => (
    //         <div>
    //             <p><strong>Pedido Mãe: </strong>{row.pedidomae}</p>
    //         </div>
    //     ),
    //     showExpandColumn: true,
    //     expandColumnPosition: 'right',
    //     expandByColumnOnly: true,
    //     onlyOneExpanding: true,
    //     nonExpandable: semPedidoMae,
    //     expandColumnRenderer: ({ expandable }) => {
    //         if (expandable) {
    //             return (
    //                 <Button variant="outline-primary" size="sm" data-placement="left" title="Pedido Mãe" >
    //                     <FontAwesomeIcon icon="sitemap" />
    //                 </Button>
    //             );
    //         }
    //         return (
    //             <></>
    //         );
    //     }
    // };
    const [node, setNode] = useState('');
    const [soma, setSoma] = useState(0);
    const selected = {
        mode: 'checkbox',
        clickToSelect: true,
        // selected: [dadosTabela()],
        // nonSelectable: dadosTabela()
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log("ROWINDEX ->", rowIndex);
            if (isSelect == true) {
                setSoma(soma + row.valor);
            } else {
                setSoma(soma - row.valor);
            }
        },

        onSelectAll: (isSelect, rows, e) => {
            // setSoma(0);
            let tamanho = rows.length;
            let sum = 0;
            if (isSelect == true) {
                for (let i = 0; i < tamanho; i++) {
                    sum += rows[i].valor;
                }
                setSoma(sum);
            } else {
                setSoma(0);
            }
        }
    }

    return (
        <>
            <Navbar />
            <Container fluid className="container-card">
                <Card className="card-visao">
                    <Card.Body>
                        {erro &&
                            <div className="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon="times" className="mr-2" />
                                {erro}
                            </div>}
                        <Row className="mt-3 mb-1">

                            <CardTitulo
                                titulo="Visão de Nota Fiscal"
                                subtitulo={"EMPRESA: " + sessionStorage.getItem("RC_CODCOLIGADA") + " - " +
                                    sessionStorage.getItem("RC_NOMECOLIGADA")}
                            />

                            <Col className='text-right'>
                                <p className="text-muted mb-0">
                                    <small>Última Atualização: {ultimaAtualizacao ?? '-'}</small>
                                </p>
                                <Button variant="outline-primary" size="sm" onClick={() => getDados(sessionStorage.getItem('RC_STATUSVISAO'))}
                                    disabled={btnAtualizaDisable}>
                                    <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                                    {btnAtualizaDisable ? "Atualizando..." : "Atualizar"}
                                </Button>
                            </Col>
                        </Row>

                        <ToolkitProvider
                            keyField="idmov"
                            data={dados ?? ''}
                            columns={colunas}
                            search>
                            {
                                parametros => (
                                    <>
                                        <Row>
                                            <Col md={3}>
                                                <Form >
                                                    <Form.Group>
                                                        <Form.Control as="select" value={sessionStorage.getItem('RC_STATUSVISAO')}
                                                            onChange={(e) => getDados(e.target.value)}>
                                                            <option value="0">Aguardando Aprovação</option>
                                                            <option value="1">Aprovados</option>
                                                            <option value="2">Reprovados</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col md={{ span: 5, offset: 4 }} className="text-right">
                                                <SearchBar placeholder="Pesquisar" {...parametros.searchProps} />
                                            </Col>
                                        </Row>

                                        <BootstrapTable
                                            {...parametros.baseProps}
                                            ref={n => setNode(n)}
                                            keyField="jsonDados"
                                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                                            hover={true}
                                            loading={carregando}
                                            selectRow={selected}
                                            bootstrap4={true}
                                            bordered={false}
                                            overlay={overlayFactory({ spinner: true })}
                                            wrapperClasses="table-responsive"
                                            // expandRow={expandRow}

                                            pagination={
                                                paginationFactory({
                                                    sizePerPage: 100,
                                                    sizePerPageList: [{
                                                        text: '100', value: 100
                                                    }, {
                                                        text: '200', value: 200
                                                    }, {
                                                        text: '300', value: 300
                                                    }, {
                                                        text: 'Tudo', value: dados.length
                                                    }]

                                                })
                                            }
                                        />
                                    </>
                                )
                            }
                        </ToolkitProvider>
                        <hr />
                        <Row>
                            <Col md={{ span: 3, offset: 10 }}>
                                <ModalAprovMultiplaNf
                                    visao={true}
                                    notasFiscais={node}
                                    pagInicial={true}
                                    redirect={false}
                                    visaoGeral={true}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <Rodape />
        </>
    );
}