import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function BtnDetalheNfRodape(props) {
    return (
        <>
            {props.status === "0" ?
                <>
                    <Link to={`/aprovaMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido}/${props.codtmv}/${props.usuariomestre}/${props.dependencia}/${props.idaprovacao}/${props.sequencial}/${props.codusuario}/${props.nomeVendedor}/1`}
                        className="btn btn-success mr-1 btn-grid" data-placement="right" title="Aprovar Movimento" >
                        <FontAwesomeIcon icon="check" className="mr-2" />
                            Aprovar
                        </Link>


                    <Link to={`/reprovaMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido}/${props.usuariomestre}/${props.dependencia}/${props.idaprovacao}/${props.codusuario}`}
                        className="btn btn-danger btn-grid mr-1" data-placement="right" title="Reprovar Movimento" >
                        <FontAwesomeIcon icon="times-circle" className="mr-2" />
                            Reprovar
                    </Link>

                    <Link to={`/reavaliarMovimento/${props.idmov}/${props.numeromov}/${props.nomefantasia !== undefined && props.nomefantasia !== null ? props.nomefantasia.replace('/', '') : '-'}/${props.valorliquido}/${props.idaprovacao}`}
                        className="btn btn-warning btn-grid mr-1" data-placement="right" title="Reavaliar Movimento" >
                        <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                            Reavaliar
                    </Link>
                </>

                :

                <>
                    <Button variant="success" className="mr-1" disabled>
                        <FontAwesomeIcon icon="check" className="mr-2" />
                            Aprovar
                    </Button>

                    {/* <Button variant="danger" className="mr-1" disabled>
                        <FontAwesomeIcon icon="times-circle" className="mr-2" />
                            Reprovar
                    </Button>

                    <Button variant="warning" className="mr-1" disabled>
                        <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                            Reavaliar
                    </Button> */}
                </>
            }
            <Link to='/notaFiscal' className="btn btn-primary btn-grid mr-1" data-placement="left" title="Volta para a visão de movimentos">
                <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                 Voltar
            </Link>
        </>
    )
}
