import React, { useState } from 'react';
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from 'react';
library.add(fas);

export default function ExibirAssinatura(props) {
    const [assinatura, setAssinatura] = useState();

    const getAssinatura = async () => {
        try {
            const response = await fetch(`${window.url_API}CarregaAssinatura?HASH=${sessionStorage.getItem('RC_HASH') + Math.random()}&ALIAS=${window.alias_RC}&CODUSUARIO=${sessionStorage.getItem('RC_USR')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("RC_TOKEN")}`
                },
            });
    
            if (!response.ok) {
                throw new Error('Erro na requisição');
            }
    
            const data = await response.json();
            console.log('Resposta da requisição:', data);
            setAssinatura(data);
        } catch (error) {
            console.error('Erro ao enviar requisição:', error);
        }
    };
    
    
    useEffect(() => {
        getAssinatura();
    }, [props.show, props.refresh])
    console.log(assinatura)
    return (
        <center>
        {assinatura && assinatura.CODIGO == 0 ?
                <>
                    <hr />
                    <h1>Assinatura Cadastrada:</h1>
                    <img src={`data:image/png;base64,${assinatura.ASSINATURA}`} alt="Assinatura" width={450}/>
                </>
                :
                <></>
            }
        </center>
    );
}