import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ModalAprovMultipla from '../../../../components/ModalAprovMultipla';
import ModalReavaliaLanc from '../../../../components/modalReavaliaLanc';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';

library.add(fas);


export default function BtnRodape(props) {
    const [show, setShow] = useState(false);
    const [disabledAprov, setDisableAprov] = useState(false);
    const [mensagem, setMensagem] = useState();
    const [erro, setErro] = useState();
    const [motivo, setValidaMotivo] = useState();
    const disable = (props.status === "Reprovado" || props.status === "Aprovado");
    const history = useHistory();

    function AprovaLancamento() {
        setDisableAprov(true);
        setMensagem('Aprovando, por gentileza aguarde...')

        let headers = '';
        headers = new Object();
        headers.idlanAtual = props.idlan;
        headers.usuariomestre = props.usuariomestre;
        headers.dependencia = props.dependencia;
        headers.idaprovacao = props.idaprovacao;
        headers.sequencial = props.sequencial;
        headers.codusuario = props.codusuario;
        let body = {
            "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"),
            "IDLAN": headers.idlanAtual,
            "CODUSUARIOAPROVACAO": sessionStorage.getItem("RC_USR"),
            "USUARIOMESTRE": headers.usuariomestre,
            "DEPENDENCIA": headers.dependencia,
            "IDAPROVACAO": headers.idaprovacao,
            "SEQUENCIAL": headers.sequencial,
            "CODUSUARIO": headers.codusuario
        };
        api.post('/AprovarLancamento', body)
            .then((response) => {

                if (response.data.CODIGO === '1') {
                    history.push("/financeiro");
                }
                else {
                    setErro("ERRO: " + response.data.DESCRICAO.toString());
                }
            })
            .catch((err) => {
                setErro(err.toString());
            })
            .finally(() => {
                setDisableAprov(false);
            })

    }


    return (
        <>
            {disable === false ?
                <>
                    <ModalAprovMultipla
                        visao={false}
                        idlanAtual={props.idlan}
                        codcoligada={props.codcoligada}
                        // codusuarioaprovacao={}
                        usuariomestre={props.usuariomestre}
                        dependencia={props.dependencia}
                        idaprovacao={props.idaprovacao}
                        sequencial={props.sequencial}
                        codusuario={props.codusuario}
                        redirect={true}
                        notaFiscal={true}
                    />
                    &nbsp;
                    <ModalReavaliaLanc
                        idlan={props.idlan}
                        idaprovacao={props.idaprovacao}
                        visao={false}
                    />

                </>

                :

                <>
                    <Button variant="success" className="mr-1" disabled>
                        <FontAwesomeIcon icon="check" className="mr-2" />
                        Aprovar
                    </Button>

                    <Button variant="warning" className="mr-1" disabled>
                        <FontAwesomeIcon icon="undo-alt" className="mr-2" />
                        Reavaliar
                    </Button>
                </>
            }

            <Link to='/financeiro' className="btn btn-primary btn-grid mr-1" data-placement="left" title="Volta para a visão de movimentos">
                <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                Voltar
            </Link>
        </>
    )
}
