import React, { useState } from 'react';
import axios from "axios";
import { Criptografa } from '../../util/security'
import { login } from "../../services/auth";

import { Form, Button, Alert, FormGroup, Spinner } from 'react-bootstrap';


export default function FormLogin(props) {
    const [usuarioInvalido, setUsuarioInvalido] = useState(false);
    const [senhaInvalida, setSenhaInvalida] = useState(false);
    const [codRetorno, setCodRetorno] = useState('');
    const [BtnEntrarDisabled, setBtnEntrarDisabled] = useState(false);
    const [erro, setErro] = useState('');


    async function HandleSingnIn(e) {
        e.preventDefault();

        setUsuarioInvalido(false);
        setSenhaInvalida(false);
        setBtnEntrarDisabled(true);

        const dados = e.target;

        const body = {
            "LOGIN": [
                {
                    "USUARIO": dados.usuario.value.trim(),
                    "ALIAS": window.alias_RC,
                    "SENHA": Criptografa(dados.senha.value.trim()),
                }
            ]
        }

        const API_AUTH_PASS = 'RCREST_API:RC@ABC123';
        const config = {
            method: 'post',
            url: `${window.url_API}/LoginGlobal`,
            headers: {
                'Authorization': `Basic ${btoa(API_AUTH_PASS)}`,
                'ALIAS': window.alias_RC,
                'HASH': sessionStorage.getItem("RC_HASH") + Math.random(),
            },
            data: body,
        }


        axios(config)
            .then(resp => {
                if (resp.status === 200)
                {
                    switch (resp.data.CODIGO)
                    {
                        case '0':
                            setUsuarioInvalido(true);
                            setBtnEntrarDisabled(false);
                            setErro('O usuário digitado não foi encontrado!');
                            break;

                        case '1':
                            sessionStorage.setItem('RC_ALTPSW', resp.data.ALTERARSENHA);
                            sessionStorage.setItem('RC_USR_PORTAL', dados.usuario.value);
                            sessionStorage.setItem('RC_USR', resp.data.CODUSUARIOOBRAS)
                            sessionStorage.setItem('RC_USRMINERACAO', resp.data.CODUSUARIOMINERACAO);
                            sessionStorage.setItem('RC_NOMEUSR', resp.data.NOME);
                            sessionStorage.setItem('RC_HASH', resp.data.HASH);
                            sessionStorage.setItem('RC_CODCOLIGADA', 'A');
                            sessionStorage.setItem('RC_TOKEN', resp.data.TOKEN);
                            sessionStorage.setItem('RC_VERSAOSERVICE', resp.data.VERSAO);

                            const perfil = {
                                "SOLICITACAO": resp.data.SOLICITACAO,
                                "APROVACAO": resp.data.APROVACAO,
                                "FINANCEIRO": resp.data.FINANCEIRO,
                                "RELATORIO": resp.data.RELATORIO,
                                "PROGRAMACAO": resp.data.PROGRAMACAO,
                                "NOTAFISCAL": resp.data.APROVACAONF
                            }
                            sessionStorage.setItem('RC_PERFIL', JSON.stringify(perfil));

                            login(resp.data.CODIGO);

                            props.historico.push("/selecionaEmpresa/0");
                            break;

                        case '2':
                            setSenhaInvalida(true);
                            setBtnEntrarDisabled(false);
                            setErro('A senha informada é inválida!');
                            break;

                        default:
                            setCodRetorno('3');
                            setBtnEntrarDisabled(false);
                            setErro('Erro ao logar, tente novamente mais tarde!');
                            break;
                    }
                }
                else
                {
                    setErro('Falha na validação da autorização da API.')
                }
            })
            .catch(err => {
                setErro(err.toString())
            })
    }

    return (
        <>
            <Form onSubmit={(e) => HandleSingnIn(e)}>
                <FormGroup className="form-label-group">
                    <Form.Control name="usuario" type="text" id="usuario" isInvalid={usuarioInvalido} className="form-control" placeholder="Usuário"
                        required autoFocus />
                    <label htmlFor="usuario">Usuário</label>
                    <Form.Control.Feedback type="invalid">
                        Usuário não encontrado.
                    </Form.Control.Feedback>
                </FormGroup>

                <FormGroup className="form-label-group">
                    <Form.Control name="senha" type="password" id="senha" isInvalid={senhaInvalida} className="form-control" placeholder="Senha"
                        required />
                    <label htmlFor="senha">Senha</label>
                    <Form.Control.Feedback type="invalid">
                        Senha Inválida.
                    </Form.Control.Feedback>
                </FormGroup>

                {codRetorno === '3' && <Alert variant="danger">{erro} </Alert>}

                <Button variant="primary" disabled={BtnEntrarDisabled}
                    className="btn-block btn-login text-uppercase font-weight-bold mb-2 mt-4" type="submit">
                    {BtnEntrarDisabled ?
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                className="mr-2"
                                role="status"
                                aria-hidden="true"
                            />
                            Verificando...</>

                        : 'Entrar'
                    }
                </Button>
            </Form>
        </>
    );
}