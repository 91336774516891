import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import useKeyPress from '../../util/useKeyPress'
import { Modal, Button, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

export default function ModalReavaliaLanc(props) {
    const [show, setShow] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [validaMotivo, setValidaMotivo] = useState(false);
    const [motivo, setMotivo] = useState('');

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    let history = useHistory();

    console.log(props);
    //Se pressionado a tecla A, abre o modal de aprovação
    const keyShow1 = useKeyPress('r');
    const keyShow2 = useKeyPress('R');
    const keyShow3 = useKeyPress('Alt');
    useEffect(() => {
        if (((keyShow1 || keyShow2) && keyShow3) && !show && !props.visao) {
            handleShow();
        }
        // eslint-disable-next-line
    }, [keyShow1, keyShow2])


    //Se pressionado a tecla ENTER com o modal aberto
    //faz a aprovação do lançamento
    const keyAprova = useKeyPress('Enter');
    useEffect(() => {
        if (keyAprova && show) {
            ReavaliaLancamento();
        }
        // eslint-disable-next-line
    }, [keyAprova])


    async function ReavaliaLancamento() {
        setCarregando(true);
        setValidaMotivo(false);

        try {
            if (motivo.length >= 15) {

                let body = {
                    "LANCAMENTO": [
                        {
                            "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"),
                            "IDLAN": props.idlan,
                            "MOTIVO": motivo,
                            "CODUSUARIO": sessionStorage.getItem('RC_USR'),
                            "IDAPROVACAO": props.idaprovacao
                        }
                    ]
                };

                const response = await api.post('/ReavaliarLancamento', body);
                if (response.data.CODIGO === '1' && props.visao === false) {
                    history.goBack();
                }
                else if (response.data.CODIGO === '1' && props.visao === true) {
                    window.location.reload();
                }
                else {
                    window.alert("Erro ao Aprovar: " + response.data.DESCRICAO);
                }
            }
            else {
                setValidaMotivo(true);
            }
        }
        catch (err) {

        }
        finally {
            setCarregando(false);
        }
    }


    return (
        <>
            <Button variant={'warning'} onClick={handleShow} className="mr-2">
                <FontAwesomeIcon icon="thumbs-down" className="" />
                {props.visao === false ? ' Reavaliar' : ''}
            </Button>

            <Modal size="lg" show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>Reavaliar Lançamento {props.idlan}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Descreva abaixo o motivo da Reavaliação.</p>

                    <Form className="mt-3">
                        <Form.Group>
                            <Form.Label>Motivo da reavaliação:</Form.Label>
                            <Form.Control as="textarea" rows={6} aria-describedby="motivoHelper"
                                onChange={e => setMotivo(e.target.value)} isInvalid={validaMotivo} required />
                            <Form.Control.Feedback type="invalid">
                                O motivo deve ter no mínimo de 15 caracteres.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>Cancelar</Button>
                    <Button variant="warning" onClick={() => ReavaliaLancamento()} disabled={carregando}>
                        <FontAwesomeIcon icon="thumbs-down" className=" mr-2" onClick={handleShow} disabled={carregando} />
                        Reavaliar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
